import React from "react";
import Login from "./component/Login";
import { Sidebar } from "./component/Sidebar";
import { Content } from "./component/Content";
import "./App.css";
import "antd/dist/antd.css";

function App() {
  const isLogged = !!sessionStorage.getItem("token");
  console.log("token",sessionStorage.getItem("token"))
  if (isLogged) {
    return (
      <>
        <div className="d-flex">
          <Sidebar />
          <Content />
        </div>
      </>
    );
  }
  else{
    return (
      <>
        <Login />
      </>
    );
  }
  
}

export default App;
