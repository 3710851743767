import React, { useState } from "react";
import { URL } from "./constantParam";
import axios from "axios";
import { Input, Space } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

export default function Login() {
  // const { login } = Api();
  // const loginsubmit = async () => {
  //   console.log("first")

  // };
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });
  const { email, password, isChecked } = loginForm;
  const onChangeHandler = (e) => {
    setLoginForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const loginsubmit = (event, values) => {
    axios

      .post(URL + "auth/login", loginForm)
      .then(function(response) {
        if (response.data.status) {
          console.log(response);
          // sessionStorage.setItem("token", response.data.token);
          // history.push("account/dashboard");

          // toast.success(response.data.message);

          sessionStorage.setItem("token", response.data.token);
          window.location.reload(false);
          // history.push("account/dashboard");
        } else {
          // setError("Please Enter Valid Username or Password");
          // toast.error("Please Enter Valid Username or Password");
          // setError(true);
        }
      })
      .catch(function(error) {
        // toast.error({ ERROR });
        // setError(true);
      });
  };
  return (
    <>
      <div className="pt-4 pb-4 d-flex vh-100 w-100 justify-content-center align-items-center" style={{ backgroundColor: "#f5f5f5" }}>
        <div className="container">
          <div className="p-5 w-100 bg-white">
          <div className="d-flex w-100 justify-content-center align-items-center">
            <h2>Login</h2>
          </div>
          <div style={{ maxWidth: 500, margin: "0 auto" }}>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Email address
              </label>
              {/* <input
                type="email"
                name="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={email}
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              /> */}
              <Input
                placeholder="Enter Email"
                value={email}
                onChange={(e) => {
                  onChangeHandler(e);
                }}
                type="email"
                name="email"
                size="large"
              />
              <div id="emailHelp" className="form-text">
                We'll never share your email with anyone else.
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputPassword1" className="form-label">
                Password
              </label>
              {/* <input
                type="password"
                className="form-control"
                id="exampleInputPassword1"
                value={password}
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              />
               */}
              <Input.Password
                placeholder="input password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                value={password}
                onChange={(e) => {
                  onChangeHandler(e);
                }}
                name="password"
                size="large"
              />
            </div>
            <button className="btn btn-primary" onClick={loginsubmit}>
              Submit
            </button>
          </div>
          </div>
        </div>
      </div>
    </>
  );
}
