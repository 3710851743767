import React, { useState } from "react";
import { Space, Table, Button, Modal, Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

export const Content = () => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      Position: "Manager",
      Date: "11-07-2022",
      Action: "Action",
    },
    {
      key: "2",
      name: "John",
      Position: "Chef",
      Date: "11-07-2022",
      Action: "Action",
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Position",
      dataIndex: "Position",
      key: "Position",
    },
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: () => (
        <Space size="middle">
          <a onClick={showModal}>Action</a>
        </Space>
      ),
    },
  ];
  return (
    <>
      <div className="content_main d-flex w-100 flex-column px-3 py-5">
        <div className="content_container">
          <div className="content_title">
            <h2>Welcome to Jaxani veg Food</h2>
          </div>
          <Table dataSource={dataSource} columns={columns} pagination={false} />
          ;
        </div>
      </div>
      <Modal
        title="Enter Your Code"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
          <Input.Password
            placeholder="input password"
            size="large" 
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
      </Modal>
    </>
  );
};
